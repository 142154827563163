<template>
  <v-app>
    <v-card-title class="headline"> {{ titleText }} Slider </v-card-title>
    <v-card-title class="headline">Slider Information</v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12" sm="6" md="8">
          <v-text-field outlined dense hide-details v-model="slider.title">
            <template v-slot:label>
              Slider Title <span class="text-danger">*</span>
            </template>
          </v-text-field>
          <span class="text-danger" v-if="$v.slider.title.$error"
            >This Title Field is required</span
          >
        </v-col>
        <v-col class="12" md="4">
          <v-text-field
            v-model="slider.position"
            hide-details
            label="Position"
            @keypress="onlyNumber"
            dense
            outlined
          >
          </v-text-field>

          <span class="text-danger" v-if="$v.slider.position.$error"
            >This Position Field is required</span
          ><br />
          <span class="text-danger" v-if="!$v.slider.position.numeric"
            >This Position Field should be numeric</span
          >
        </v-col>
        <v-col v-bind:md="slider.file_image != null ? 8 : 12">
          <v-file-input
            v-model="slider.file_image"
            accept="image/*"
            outlined
            dense
            @change="previewImage"
            prepend-inner-icon="mdi-file"
            label="Image"
            prepend-icon=""
          >
          </v-file-input>
          <span class="text-danger" v-if="$v.slider.file_image.$error"
            >This Image Field is required</span
          >
        </v-col>
        <v-col cols="12" sm="12" md="4" v-if="imgUrl != null">
          <div class="text-center">
            <v-img
              :lazy-src="imgUrl"
              max-height="150"
              max-width="250"
              class="img-thumbnail"
              :src="imgUrl"
            ></v-img>
          </div>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            outlined
            dense
            label="Heading Text"
            v-model="slider.heading_text"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            outlined
            dense
            label="Sub Heading Text"
            v-model="slider.sub_heading_text"
          ></v-text-field>
        </v-col>
        <v-col md="12">
          <v-card-title class="headline">Button</v-card-title>
        </v-col>

        <v-col md="6">
          <v-switch
            v-model="slider.show_button"
            :label="
              `Button Status ` + ':' + ' ' + slider.show_button ? 'Yes' : 'No'
            "
          ></v-switch>
        </v-col>

        <v-col cols="6" sm="6" md="12" v-if="slider.show_button">
          <v-text-field
            outlined
            dense
            label="Button Text"
            v-model="slider.button_text"
          ></v-text-field>
        </v-col>

        <v-col cols="6" sm="6" md="12" v-if="slider.show_button">
          <v-text-field
            outlined
            dense
            label="Button Link*"
            v-model="slider.link"
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="12">
          Description
          <ckeditor :config="editorConfig" v-model="slider.description">
          </ckeditor>
        </v-col>

        <v-col cols="6">
          <v-switch
            v-model="slider.is_active"
            :label="slider.is_active ? 'Active' : 'Inactive'"
          ></v-switch>
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <v-btn class="ma-2 float-right" color="primary" @click="createSlider">
            {{ sliderId ? "Update" : "Save" }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-app>
</template>
<script>
import { required, numeric, requiredIf } from "vuelidate/lib/validators";
import SliderService from "@/service/cms/slider/SliderService";

const slider = new SliderService();

export default {
  data() {
    return {
      title: "",
      imgUrl: null,
      slider: {
        title: "",
        type: "",
        heading_text: "",
        subheading_text: "",
        file_image: null,
        show_button: false,
        button_target: false,
        button_text: "",
        button_link: "",
        second_show_button: false,
        second_button_target: false,
        second_button_text: "",
        second_button_link: "",
        position: "",
        description: "",
        is_active: true
      },
      errors: [],
      editorConfig: {
        toolbar: [
          [
            "Font",
            "FontSize",
            "FontColor",
            "Bold",
            "Italic",
            "Underline",
            "Strike",
            "RemoveFormat",
            "Link",
            "NumberedList",
            "BulletedList",
            "TextColor",
            "BGColor"
          ]
        ],
        extraPlugins: ["font", "colorbutton"],
        colorButton_enableMore: true
      }
    };
  },
  validations: {
    slider: {
      title: { required },
      position: { required, numeric },
      file_image: {
        required: requiredIf(function() {
          return this.sliderId == null;
        })
      }
    }
  },
  methods: {
    back() {
      this.$tabs.close();
      this.$tabs.open("/popup");
    },
    convertToFormData() {
      let formData = new FormData();
      for (let key in this.slider) {
        if (
          key === "file_image" &&
          this.slider[key] != null &&
          this.slider[key] != undefined
        ) {
          formData.append("file_image", this.slider[key]);
        } else {
          if (this.slider[key]) {
            formData.append(key, this.slider[key]);
          }
        }
      }
      return formData;
    },
    createSlider() {
      this.$v.$touch();
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset();
        }, 6000);
      } else {
        if (this.sliderId != null || this.sliderId != undefined) {
          let formData = this.convertToFormData();
          slider
            .update(this.sliderId, formData)
            .then(response => {
              if (response.data.status == "OK") {
                this.$snotify.success("Slider updated successfully");
                this.back();
              }
            })
            .catch(error => {
              this.errors = error.response.data.errors;
            });
        } else {
          let formData = this.convertToFormData();
          slider
            .store(formData)
            .then(response => {
              if (response.data.status == "OK") {
                this.$snotify.success("Slider created successfully");
                this.back();
              }
            })
            .catch(error => {
              this.errors = error.response.data.errors;
            });
        }
      }
    },
    sliderDetail() {
      if (this.sliderId != null || this.sliderId != undefined) {
        slider.show(this.sliderId).then(response => {
          this.slider = response.data.slider;
          this.imgUrl = this.slider.image_path["real"];
        });
      }
    },
    previewImage() {
      if (this.slider.file_image != null) {
        this.imgUrl = URL.createObjectURL(this.slider.file_image);
      } else {
        this.imgUrl = null;
      }
    },
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        // 46 is dot
        $event.preventDefault();
      }
    }
  },
  mounted() {
    this.sliderDetail();
  },
  computed: {
    titleText() {
      if (this.sliderId != null || this.sliderId != undefined) {
        return "Edit";
      } else {
        return "Create";
      }
    },
    sliderId() {
      return this.$route.params.sliderId;
    }
  }
};
</script>
